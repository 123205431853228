import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    position: relative;

    .wrapper-public-blocks-topbar-desktop {
        display: block;
        z-index: 10;
    }

    .wrapper-public-blocks-topbar-mobile {
        display: none;
        z-index: 10;
    }

    &.disabled-padding {
        padding: 0;
    }

    .wrapper-content {
    }

    @media all and (max-width: ${vars.tabletS}) {
        .wrapper-public-blocks-topbar-desktop {
            display: none;
        }

        .wrapper-public-blocks-topbar-mobile {
            display: block;
        }
    }
`;
