export default {
    fontPrimary: 'Lato, sans-serif',
    fontSecondary: 'Epilogue, sans-serif',
    fontFallback: 'Helvetica, Arial, sans-serif',

    colorPrimaryBlue: '#16233E',
    colorPrimaryDark: '#13162B',
    colorPrimaryLight: '#f5f5fa',
    colorSecondaryBlue: '#959DD2',
    colorSecondaryDark: '#D8DBEC',
    colorSecondaryLight: '#FFFFFF',

    colorPrimaryCTA: '#2065EA',
    colorSecondaryCTA: '#608AED',

    colorSuccess: '#4CAF50',
    colorError: '#da626a',
    colorDisabled: '#D8D8D8',

    colorTextDark: '#333333',
    colorTextLight: '#999999',
    colorTextWhite: '#FFFFFF',
    colorTextBlue: '#608AED',
    colorTextBlueLighter: '#A9C3FF',
    colorTextDarkLighter: '#3B4C68',

    defaultRadius: '0.3em',
    defaultShadow: 'rgba(149, 157, 165, 0.080) 0px 4px 10px',

    desktopXL: '1920px',
    desktopL: '1750px',
    desktopM: '1600px',
    desktopS: '1360px',
    desktopXS: '1200px',

    tabletL: '1024px',
    tabletS: '768px',

    mobileL: '640px',
    mobileM: '480px',
    mobileS: '360px',
    mobileXS: '281px',
};
