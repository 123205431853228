export enum Routes {
    // Portal routes
    PublicHome = '/',

    PublicHomeDetails = '/#details',
    PublicHomeContact = '/#contact',
    PublicHomeSevices = '/#services',

    PublicCompanyRegistrationForm = '/rejestracja-firmy',
    PublicPasswordReset = '/zmien-haslo',
    PublicContactForm = '/contact-form',

}
