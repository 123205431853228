import { FunctionComponent } from 'react';

import StyledComponent from './styles';
import { Props } from './types';

const LayoutFormsInputLabel: FunctionComponent<Props> = ({ children }) => {
    return (
        <StyledComponent className="layout-forms-input-label">
            {children}
        </StyledComponent>
    );
};

export default LayoutFormsInputLabel;