import styled from '@emotion/styled';

export default styled.div`
    width: 100%;

    .checkbox-wrapper {
        position: relative;
        text-align: left;
        cursor: pointer;
        display: inline-block;

        .checkbox-trigger {
            width: 20px;
            height: 20px;
            background-color: #FFFFFF;
            display: inline-block;
            cursor: pointer;
            position: relative;
            border: 1px solid #666666;
            border-radius: .2em;
        }

        .checkbox {
            position: absolute;
            z-index: -1;
            width: 1px;
            height: 1px;
            margin: 0;
            opacity: .01;

            &:checked + .checkbox-trigger,
            &[value="true"] + .checkbox-trigger {
                &:after {
                    content: "";
                    display: inline-block;
                    position: absolute;
                    background: black;
                    inset: 5px;
                    width: 50%;
                    height: 50%;
                }
            }
        }
    }

    .label {
        display: inline-block;
        padding-left: .9em;
        text-align: left;
        line-height: 120%;
        font-size: .8em;
        color: #121212;
        max-width: 90%;
        margin-top: -.2em;

        a {
            text-decoration: underline;
            color: #FFFFFF;
        }

        .expand-trigger {
            cursor: pointer;
            margin-left: .5em;
            text-decoration: underline;
        }
    }

    .layout-forms-input-wrapper {
        .internal-wrapper {
            border: none;
            border-radius: 0;

            .input-body {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
            }
        }
    }

    .error-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: .5em;

        .error-message {
            font-size: .8em;
            color: #FF0000;
        }
    }

    &.disabled {
        opacity: .5;
    }

    &.bold {
        .label {
            font-weight: 600;
        }
    }

    &.style-secondary {
        .layout-forms-input-wrapper {
            .internal-wrapper {
                .input-body {
                    display: flex;
                    align-items: center;

                    .checkbox-wrapper {
                        .checkbox-trigger {
                            border: 1px solid #C1C1C1;
                        }

                        .checkbox {
                            &:checked + .checkbox-trigger,
                            &[value="true"] + .checkbox-trigger {
                                &:after {
                                    background: transparent;
                                    background-image: url(/images/cart/check-gold.svg);
                                    background-repeat: no-repeat;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.style-required {
        .layout-forms-input-wrapper {
            .internal-wrapper {
                .input-body {
                    display: flex;
                    align-items: center;

                    .checkbox-wrapper {
                        .checkbox-trigger {
                            border: 2px solid #eb8817;
                        }
                    }
                }
            }
        }
    }

    &.style-circle {
        .layout-forms-input-wrapper {
            .internal-wrapper {
                .input-body {
                    display: flex;
                    align-items: center;

                    .checkbox-wrapper {
                        .checkbox-trigger {
                            border: 1px solid #262626;
                            border-radius: 50%;
                        }

                        .checkbox {
                            &:checked + .checkbox-trigger,
                            &[value="true"] + .checkbox-trigger {
                                &:after {
                                    background-color: #BA9449;
                                    border-radius: 50%;
                                    background-repeat: no-repeat;
                                    background-size: cover;
                                    top: 50%;
                                    left: 50%;
                                    width: .7em;
                                    height: .7em;
                                    transform: translate(-50%, -50%);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

