import { ChangeEvent } from 'react';

import {
    InputDecorator,
    Props as InputWrapperProps,
    ValidationActions,
} from 'components/layout/forms/InputWrapper';

import Component from './component';

export interface Props extends InputWrapperProps {
    name: string;
    className?: string;
    value?: string | number;
    onChange: (event: ChangeEvent<any>) => any;
    style?: InputStyles;
    styles?: object;
    error?: string;
    disabled?: boolean;
    validationAction?: ValidationActions;
    decoratorLeft?: InputDecorator;
    decoratorRight?: InputDecorator;
    required?: boolean;
    disableDefaultRequiredBehaviour?: boolean;

    expandable?: {
        enabled: boolean;
        label: string;
        triggerText?: string;
    };
}

export enum InputStyles {
    Primary = 'primary',
    Secondary = 'secondary',
    Circle = 'circle',
    Required = 'required',
}

Component.defaultProps = {
    value: '',
    className: '',
    style: InputStyles.Primary,
    styles: {},
    error: null,
    disabled: false,
    validationAction: ValidationActions.Decorator,
    decoratorLeft: null,
    decoratorRight: null,
    expandable: null,
    required: false,
};

export default Component;
