import React from 'react';
import DisplayNavProvider from 'providers/hiddenNav';

import WrapperPublic from 'components/wrappers/Public';

import { Props } from './index';
import StyledComponent from './styles';


const WrapperApp: React.FC<Props> = ({ children }) => {
    return (
        <StyledComponent className="wrapper-app">
            <DisplayNavProvider>
                <WrapperPublic>
                    {children}
                </WrapperPublic>
            </DisplayNavProvider>
        </StyledComponent>
    );
};

export default WrapperApp;
