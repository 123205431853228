import { ChangeEvent, ClipboardEvent } from 'react';

import {
    InputDecorator,
    Props as InputWrapperProps,
} from 'components/layout/forms/InputWrapper/types';

export interface Props extends InputWrapperProps {
    className?: string;
    name: string;
    type?: string;
    placeholder?: string;
    value?: string | number;
    onChange: (event: ChangeEvent<HTMLInputElement> | ClipboardEvent<HTMLInputElement>) => any;
    style?: InputStyles;
    styles?: object;
    error?: string;
    disabled?: boolean;
    decoratorLeft?: InputDecorator;
    decoratorRight?: InputDecorator;
}

export enum InputStyles {
    Primary = 'primary',
}
