import styled from '@emotion/styled';

import { lightenDarkenColor } from 'utils/colors';

import vars from 'theme/styles/vars';

export default styled.div`
    width: 100%;

    input {
        width: 100%;
        height: 3em;
        border: none;
        padding: 0 1em;
        border-radius: .5em;
        color: ${vars.colorTextDark};
        font-size: .9em;

        &::placeholder {
            color: ${lightenDarkenColor(vars.colorPrimaryLight, -100)};
        }

        &:disabled {
            background-color: #d7e2e4;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        &[type=number] {
            -moz-appearance: textfield;
        }
    }

    &.decorator-left {
        input {
            padding-left: 2.5em;
        }
    }
    &.decorator-right {
        input {
            padding-right: 2.5em;
        }
    }

    @media all and (max-width: ${vars.mobileL}) {
        input {
            font-size: 1.1em;
        }
    }
`;
