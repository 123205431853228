import { FunctionComponent, useState } from 'react';
import classNames from 'classnames';

import { Routes } from 'types/routes';

import Button from 'components/layout/Button';
import { ButtonStyles, ButtonVariants } from 'components/layout/Button/types';
import LayoutContainer from 'components/layout/LayoutContainer';
import Link from 'components/layout/Link';
import Logo from 'components/layout/Logo';

import StyledComponent from './styles';
import { Props } from './types';

const WrapperPublicBlocksTopbar: FunctionComponent<Props> = ({ className }) => {
    const [isActive, setIsActive]: [boolean, Function] = useState(false);

    return (
        <StyledComponent className={classNames('wrapper-public-blocks-topbar-mobile', className)}>
            <LayoutContainer>
                <div className="columns">
                    <div className="column-brand">
                        <Link
                            className="logo-link"
                            href={Routes.PublicHome}
                        >
                            <Logo
                                width={240}
                                height={66}
                            />
                        </Link>
                    </div>
                    <button
                        className={classNames('column-button', {
                            'is-active': Boolean(isActive),
                        })}
                        onClick={() => setIsActive((prevState:boolean) => !prevState)}
                    >
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                    </button>
                </div>
                {isActive && (
                    <div className="active-nav">
                        <nav className="column-nav">
                            <Link
                                className="nav-link"
                                href="#services"
                            >
                                Services
                            </Link>
                            <Link
                                className="nav-link"
                                href="#details"
                            >
                                    About
                            </Link>
                            <Link
                                className="nav-link"
                                href="#contact"
                            >
                                Kontakt
                            </Link>
                        </nav>
                        <div className="column-cta">
                            <Button
                                className="column-cta-button"
                                style={ButtonStyles.Secondary}
                                variant={ButtonVariants.Outline}
                            >
                                    CTA
                            </Button>
                        </div>
                    </div>
                )}
            </LayoutContainer>
        </StyledComponent>
    );
};

export default WrapperPublicBlocksTopbar;
