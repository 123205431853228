import { FunctionComponent, useContext } from 'react';
import classNames from 'classnames';
import { HiddenNavContext } from 'providers/hiddenNav';

import EuropeanUnionBar from './blocks/EuropeanUnionBar';
import Footer from './blocks/Footer';
import Topbar from './blocks/Topbar';
import TopbarMobile from './blocks/TopbarMobile';
import { Props } from './index';
import StyledComponent from './styles';

const WrapperPublic: FunctionComponent<Props> = ({ children }) => {
    const [isHidden]: [boolean] = useContext(HiddenNavContext);

    return (
        <>
            <StyledComponent className={classNames('wrapper-public', {
                'disabled-padding': Boolean(isHidden),
            })}
            >
                <EuropeanUnionBar isClosable={false} />
                <Topbar
                    className={classNames({
                        'hidden-nav': Boolean(isHidden),
                    })}
                />
                <TopbarMobile
                    className={classNames({
                        'hidden-nav': Boolean(isHidden),
                    })}
                />
                <div className="wrapper-content">
                    {children}
                </div>
                <Footer />
            </StyledComponent>
        </>
    );
};

export default WrapperPublic;