import styled from '@emotion/styled';

import { lightenDarkenColor } from 'utils/colors';

import vars from 'theme/styles/vars';

export default styled.div`
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 9;
    padding: 1em 0;
    transition: background-color 150ms ease;
    background-color: ${vars.colorPrimaryBlue};

    .columns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1em;

        .column-brand {
            max-width: 12em;

            .layout-logo {
                .logo-img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .column-nav {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .nav-link {
                margin-right: 3em;
                color: ${vars.colorTextWhite};

                &:hover {
                    color: ${lightenDarkenColor(vars.colorTextDark, 100)};
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    &.hidden-nav {
        display: none;
    }

    @media all and (max-width: ${vars.mobileS}) {
        .columns {
            flex-direction: column;
        }
    }
`;
