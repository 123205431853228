import { FunctionComponent } from 'react';
import classnames from 'classnames';

import Image from 'components/layout/Image';

import StyledComponent from './styles';
import { Props } from './types';

const LayoutIconCircle: FunctionComponent<Props> = ({ iconSrc, className, width, height }) => ((
    <StyledComponent
        className={classnames('layout-icon-circle', className)}
        aria-hidden="true"
    >
        <Image
            className="icon"
            src={iconSrc}
            layout='fixed'
            objectFit='scale-down'
            objectPosition="center"
            width={width || 50}
            height={height || 50}
        />
    </StyledComponent>
));

export default LayoutIconCircle;