import { FunctionComponent } from 'react';

import logo from 'theme/images/logo.png';
import logoDark from 'theme/images/logo-dark.png';

import StyledComponent from './styles';
import { Props } from './types';

const LayoutLogo: FunctionComponent<Props> = ({ altText, width, height, dark }) => ((
    <StyledComponent className="layout-logo">
        <img
            className="logo-img"
            src={dark ? logoDark.src : logo.src}
            alt={altText || 'Rehab'}
            width={width}
            height={height}
        />
    </StyledComponent>
));

export default LayoutLogo;
