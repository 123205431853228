import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    width: 100%;
    position: relative;
    padding: 4em 0 0;

    .main-logo {
        text-align: center;

        img {
            max-width: 260px;
        }
    }

    .columns {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 4em 0;

        .column-logo {
            position: relative;
            width: 10em;
        }

        .column-nav {
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            align-items: flex-start;

            &:nth-of-type(1) {
                max-width: 30%;
            }

            &:nth-of-type(2) {
                max-width: 25%;
            }

            &:nth-of-type(3) {
                max-width: 45%;
            }

            .agreement {
                margin-top: 1em;
                display: flex;
                gap: 1em;

                .text {
                    font-size: 0.9em;
                }
            }

            .layout-title {
                color: ${vars.colorPrimaryBlue};
                font-weight: 700;
            }

            .nav-link {
                margin: 0.5em 0;

                &:hover {
                    text-decoration: underline;
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    .bottom {
        color: ${vars.colorTextLight};
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: rgb(20, 55, 87);
        background: radial-gradient(circle, rgba(20, 55, 87, 1) 0%, rgba(22, 35, 62, 1) 100%);

        .socials {
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 3em 0;
            background-color: ${vars.colorPrimaryBlue};

            .social-links {
                display: flex;
                gap: 2em;

                .social-link {
                    .social-link-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 5em;
                        height: 5em;
                        background-color: ${vars.colorPrimaryBlue};

                        & > span {
                            transform: scale(2);
                        }

                        &:hover {
                            filter: brightness(1.2);
                        }
                    }
                }
            }
        }

        .copy-and-policy {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1em;
            padding: 1em;

            .bottom-links,
            .bottom-copyright {
                color: ${vars.colorTextWhite};
                display: flex;
                justify-content: center;
                gap: 1.5em;

                .link {
                    @media (pointer: fine) {
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: ${vars.mobileL}) {
        .columns {
            display: flex;
            flex-flow: column;
            gap: 3em;
            font-size: 1.3em;
            margin: 0 0.4em;

            .column-nav {
                .agreement {
                    margin-top: 1em;
                    display: flex;
                    gap: 0;

                    .text {
                        font-size: 0.9em;
                    }
                }

                .layout-forms-input-row {
                    flex-wrap: wrap;

                    .layout-forms-input {
                        width: 100%;
                        margin: 0;
                    }

                    .layout-button {
                        width: 100%;

                        .button {
                            width: 100%;
                        }
                    }
                }

                &:nth-of-type(1),
                &:nth-of-type(2),
                &:nth-of-type(3) {
                    max-width: 100%;
                }
            }
        }

        .bottom {
            .copy-and-policy {
                flex-direction: column-reverse;
            }
        }
    }
`;
