import { FunctionComponent, ReactNode } from 'react';
import classnames from 'classnames';

import InputLabel from 'components/layout/forms/InputLabel';

import StyledComponent from './styles';
import { InputDecorator, Props } from './types';

const LayoutFormsInputWrapper: FunctionComponent<Props> = ({
    children,
    label,
    error,
    decoratorLeft,
    decoratorRight,
}) => {
    const getDecorator = (decorator: InputDecorator): ReactNode => {
        if (!decorator?.visible) return null;

        return (
            <div
                className={classnames(
                    'input-decorator',
                    {
                        'input-decorator-left': Boolean(decoratorLeft),
                        'input-decorator-right': Boolean(decoratorRight),
                    }
                )}
            >
                {Boolean(decoratorLeft) && decoratorLeft?.children}
                {Boolean(decoratorRight) && decoratorRight?.children}
            </div>
        );
    };

    return (
        <StyledComponent
            className={classnames(
                'layout-forms-input-wrapper',
                { error: Boolean(error) }
            )}
        >
            {label && (
                <div className="label-wrapper">
                    {label && (
                        <InputLabel>
                            {label}
                        </InputLabel>
                    )}
                </div>
            )}
            <div className="internal-wrapper">
                {getDecorator(decoratorLeft)}
                <div className="input-body">
                    {children}
                </div>
                {getDecorator(decoratorRight)}
            </div>
            {error && (
                <p className="error-info">{error}</p>
            )}
        </StyledComponent>
    );
};

export default LayoutFormsInputWrapper;
