import { ChangeEvent, FunctionComponent, useState } from 'react';

import { Routes } from 'types/routes';

import FbIcon from 'theme/icons/social/facebook.svg';
import TwIcon from 'theme/icons/social/instagram.svg';
import InstaIcon from 'theme/icons/social/twitter.svg';

import Button from 'components/layout/Button';
import { ButtonStyles } from 'components/layout/Button/types';
import Checkbox, { InputStyles } from 'components/layout/forms/Checkbox';
import Input from 'components/layout/forms/Input';
import InputRow from 'components/layout/forms/InputRow';
import IconCircle from 'components/layout/IconCricle';
import LayoutContainer from 'components/layout/LayoutContainer';
import Link from 'components/layout/Link';
import Logo from 'components/layout/Logo';
import Title from 'components/layout/Title';

import StyledComponent from './styles';
import { Props } from './types';

const WrapperPublicBlocksFooter: FunctionComponent<Props> = () => {
    const [formValues, setFormValues] = useState(null);

    return (
        <StyledComponent className="wrapper-public-blocks-footer">
            <LayoutContainer>
                <div className="main-logo">
                    <Logo dark />
                </div>
                <div className="columns">
                    <nav className="column-nav">
                        <Title title="Menu" />
                        <Link
                            className="nav-link"
                            href={Routes.PublicHome}
                        >
							Home
                        </Link>
                        <Link
                            className="nav-link"
                            href={Routes.PublicHomeDetails}
                        >
							Możliwości
                        </Link>
                        <Link
                            className="nav-link"
                            href={Routes.PublicContactForm}
                        >
							Kontakt
                        </Link>
                    </nav>
                    <nav className="column-nav">
                        <Title title="Dla firm" />
                        <Link
                            className="nav-link"
                            href={Routes.PublicCompanyRegistrationForm}
                            prefetch={false}
                        >
							Formularz
                        </Link>
                        <Link
                            className="nav-link"
                            href={Routes.PublicPasswordReset}
                            prefetch={false}
                        >
							Zresetuj hasło
                        </Link>
                    </nav>
                    <nav className="column-nav">
                        <Title title="Zapisz się do Newslettera" />
                        <InputRow>
                            <Input
                                name="email"
                                type="email"
                                placeholder="Twój adres email"
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setFormValues({
                                        ...formValues,
                                        city: e.target.value,
                                    })
                                }
                                value={formValues?.city}
                            />
                            <Button
                                className="send-button"
                                style={ButtonStyles.Secondary}
                            >
								Wyślij
                            </Button>
                        </InputRow>
                        <div className="agreement">
                            <div className="checkbox">
                                <Checkbox
                                    name="regulations"
                                    style={InputStyles.Primary}
                                    value={formValues?.regulations}
                                    decoratorLeft={{ visible: false }}
                                    decoratorRight={{ visible: false }}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        const value = e.target.checked;
                                        setFormValues({ ...formValues, regulations: value });
                                    }}
                                />
                            </div>
                            <div className="text">
								Wyrażam zgodę na przesyłanie na mój adres e-mail informacji o
								nowościach, promocjach i usługach dotyczących platformy REHAB.
                            </div>
                        </div>
                    </nav>
                </div>
            </LayoutContainer>
            <div className="bottom">
                <div className="socials">
                    <div className="social-links">
                        <Link
                            className="social-link"
                            href="/"
                        >
                            <IconCircle
                                className="social-link-icon"
                                iconSrc={FbIcon}
                                width={20}
                                height={20}
                            />
                        </Link>
                        <Link
                            className="social-link"
                            href="/"
                        >
                            <IconCircle
                                className="social-link-icon"
                                iconSrc={TwIcon}
                                width={20}
                                height={20}
                            />
                        </Link>
                        <Link
                            className="social-link"
                            href="/"
                        >
                            <IconCircle
                                className="social-link-icon"
                                iconSrc={InstaIcon}
                                width={20}
                                height={20}
                            />
                        </Link>
                    </div>
                </div>
                <LayoutContainer>
                    <div className="copy-and-policy">
                        <div className="bottom-copyright">
							Copyright © 2023. All Rights Reserved.
                        </div>
                        <div className="bottom-links">
                            <Link
                                className="link"
                                href="/"
                            >
								Regulamin
                            </Link>
                            <Link
                                className="link"
                                href="/"
                            >
								Polityka prywatności
                            </Link>
                        </div>
                    </div>
                </LayoutContainer>
            </div>
        </StyledComponent>
    );
};

export default WrapperPublicBlocksFooter;
